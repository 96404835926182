<template>
  <div>
    <div class="container footer">
      <div
          class="footer-menu-tab d-flex justify-content-center"
          data-aos="fade-down"
          data-aos-duration="3000"
      >
        <span @click="scrollToSection('#our-process')">Our Process</span>
        <span @click="scrollToSection('#our-partners')">Our Partners</span>
        <span @click="scrollToSection('#technologies')">Technologies</span>
        <span @click="scrollToSection('#about-us')">About us</span>
        <span @click="scrollToSection('#contact-us')">Contact us</span>
      </div>
      <div class="d-flex justify-content-center socials">
        <a href="https://www.linkedin.com/company/11735659/admin/feed/posts/">
          <img src="../assets/img/linkedIn.png" alt="LinkedIn"/>
        </a>
        <a href="https://www.facebook.com/codeIdeaa">
          <img src="../assets/img/facebook.png" alt="Facebook"/>
        </a>
        <a href="https://www.instagram.com/codeidea_?igsh=MWFta2ZkMzBhMWxldw==">
          <img src="../assets/img/instagram.png" alt="Instagram"/>
        </a>
      </div>
      <div class="d-flex justify-content-center rights">
        <span>© 2024 Copyright by CodeIdea LLC. All Rights Reserved.</span>
      </div>
      <div class="d-flex justify-content-between mt-5 pb-5">
        <div class="widget">
          <a
              href="https://www.designrush.com/agency/profile/codeidea-llc"
              target="_blank"
          >
            <img
                alt="verified agency on DesignRush"
                src="../assets/img/background-white.png"
            />
          </a>
        </div>
        <div>
          <div data-designrush-widget data-agency-id="82605" data-style="dark"></div>

          <noscript><a href="https://www.designrush.com/agency/profile/codeidea-llc#reviews" target="_blank">REVIEW US ON
            DESIGNRUSH</a></noscript>
        </div>
        <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="7" data-height="65"
             data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="2227698"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterMenu',
  mounted() {
    setTimeout(() => {
      const iframeElement = document.querySelector('iframe');
      console.dir(iframeElement);
    }, 1000);
  },
  methods: {
    scrollToSection(selector) {
      const targetElement = document.querySelector(selector);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: 'smooth'
        });
      }
    }
  }
};
</script>

<style scoped>
.footer {
  margin-top: 187px;
}

.footer-menu-tab {
  gap: 115px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.socials {
  margin-top: 37px;
  gap: 5px;
}

.rights {
  margin-top: 36px;
  color: rgba(144, 144, 144, 1);
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.widget {
  width: 280px;
}

span {
  cursor: pointer;
}

@media (max-width: 991px) {
  .footer-menu-tab {
    gap: 30px;
  }

  .footer {
    margin-top: 100px;
  }
}

@media (max-width: 511px) {
  .footer-menu-tab {
    gap: 20px;
  }
}
</style>
